import type { UserProfile } from '@autobid/nuxt-pinia-store/types/User'
import { APP_ID_NAME_MAP } from '@autobid/ui/constants/APP_ID_NAME_MAP'
import { BEAMER_BID_ACCESS_KEY } from '@autobid/ui/constants/BEAMER_BID_ACCESS_KEY'
import type { QueryClient } from '@tanstack/vue-query'
import { createError } from 'nuxt/app'

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.client) return
  const auctionId = to.params.auctionId
  if (typeof auctionId !== 'string' || isNaN(Number(auctionId.toString()))) {
    throw createError({
      statusCode: 404
    })
  }
  // eslint-disable-next-line vue-composable/composable-placement
  const APP_ID = useRuntimeConfig().public.APP_ID
  // eslint-disable-next-line vue-composable/composable-placement
  const headers = useRequestHeaders(['cookie'])
  // eslint-disable-next-line vue-composable/composable-placement
  const cookie = useCookie(BEAMER_BID_ACCESS_KEY)
  // eslint-disable-next-line vue-composable/composable-placement
  const { $queryClient } = useNuxtApp() as { $queryClient: QueryClient }

  // to keep it simple take advantage of webapi auth, webapi returns 401 if user is not authenticated
  const profile = (await $fetch('/api/backend', {
    method: 'POST',
    headers,
    body: {
      queryApi: 'webapi',
      queryUrl:
        '/v2/profile?extensions=address-book,contact-person,company,parameters',
      headers: {
        'Accept-language': 'en'
      }
    }
  })) as UserProfile

  $queryClient.setQueryData(['profile', ref(true), profile.id], profile)

  const REQUIRED_PERM = 'netlive-beamer'
  const BID_PERM = 'saalbieter'

  const currentAppRoles = new Set(
    profile.roles.permissions[
      APP_ID_NAME_MAP[APP_ID as keyof typeof APP_ID_NAME_MAP]
    ]
  )
  const hasAccess = currentAppRoles.has(REQUIRED_PERM)
  if (!hasAccess) {
    throw createError({
      statusCode: 403
    })
  }

  const hasBidAccess = currentAppRoles.has(BID_PERM)
  cookie.value = !!hasBidAccess
})
